import axios from 'axios'

import assignIf from './assign-if'
import { paramCase } from 'param-case'

import {
  promptReLogin,
  showBizErrorMessage,
  showFatalErrorMessage
} from './message'

import * as loadingAnimation from './loading-animation'
import * as blockingAnimation from './blocking-animation'

const METHODS = ['get', 'delete', 'head', 'options', 'post', 'put', 'patch']
const DATALESS_METHODS = ['get', 'delete', 'head', 'options']
const EXTRA_REQ_OPTIONS = [
  'ajax',
  'requestName',
  'blocking',
  'cacheable',
  'failureCallback',
  'throwAllException',
  'throwBizException',
  'promptBizException',
  'logFatalException',
  'throwFatalException',
  'promptFatalException'
]

class HttpError extends Error {
  constructor (status, data = {}) {
    super()
    Object.assign(this, {
      status,
      ...data
    })
  }
}

function isUnauthError (status) {
  return [401, 418, 438].indexOf(status) !== -1
}

function isBizError (status) {
  return status >= 400 && status < 500 && status !== 404
}

function handleBizException (status, options, data) {
  if (options.promptBizException !== false) {
    showBizErrorMessage(
      data.desc || data.code || '未知错误',
      options.failureCallback
    )
  }
}

function handleFatalException (e, status, options, data) {
  const { requestName, promptFatalException, logFatalException } = options

  if (promptFatalException !== false) {
    const s = requestName || '请求服务端'
    showFatalErrorMessage(status ? `${s}返回错误` : `${s}失败`)
  }
  if (logFatalException !== false) {
    console.error('[REQUEST FAILURE]', e, options)
  }
}

function handleError (e, options) {
  const res = Object(e.response)
  const data = Object(res.data)
  const status = parseInt(res.status)

  let throwException = options.throwAllException

  if (isUnauthError(status)) {
    promptReLogin()
  } else if (isBizError(status)) {
    throwException = throwException || options.throwBizException
    handleBizException(status, options, data)
  } else {
    throwException = throwException || options.throwFatalException
    handleFatalException(e, status, options, data)
  }

  if (throwException) {
    throw new HttpError(status, data)
  }
}

function startUI (blocking = null, method) {
  if (blocking === true ||
    (blocking === null && method.toLowerCase() !== 'get')
  ) blockingAnimation.show()
  loadingAnimation.start()
}

function completeUI (blocking = null, method) {
  if (blocking === true ||
    (blocking === null && method.toLowerCase() !== 'get')
  ) blockingAnimation.hide()
  loadingAnimation.complete()
}

const contextHeaders = {}

function setContextHeaders (values) {
  const headerValues = Object.fromEntries(
    Object.keys(values).map(
      el => {
        const key = el.indexOf('x-') === 0 ? el : `x-${paramCase(el)}`

        return [key, values[el]]
      }
    )
  )

  Object.assign(contextHeaders, headerValues)
}

async function request (options) {
  const { method, params, ajax, cacheable, blocking, mockEnable } = options

  const headers = assignIf({}, {
    ...contextHeaders,
    ...options.headers,
    'x-mock-enable': mockEnable ? true : null,
    'x-client-ajax': ajax === false ? null : 'true'
  })

  if (cacheable !== false) {
    options.params = {
      __: (+new Date()).toString(36),
      ...params
    }
  }

  startUI(blocking, method)
  try {
    const axiosOption = { ...options, headers }
    EXTRA_REQ_OPTIONS.forEach(prop => delete axiosOption[prop])

    const res = await axios.request(axiosOption)

    return res.data
  } catch (e) {
    handleError(e, options)
  } finally {
    completeUI(blocking, method)
  }
}

const http = {
  setContextHeaders,
  request
}

METHODS.forEach(method => {
  http[method] = async function (url, data, options) {
    if (url.url) {
      options = url
      url = undefined
      data = undefined
    } else if (DATALESS_METHODS.indexOf(method) !== -1) {
      options = data
      data = undefined
    }

    const res = await request({
      url,
      data,
      ...options,
      method
    })

    return res
  }
})

export default http
