export function injectGrowingIOScript (context) {
  if (window?.self !== window?.top) return

  let content = '!function(e,t,n,g,i){e[i]=e[i]||function(){(e[i].q=e[i].q||[]).push(arguments)},n=t.createElement("script"),tag=t.getElementsByTagName("script")[0],n.async=1,n.src=(\'https:\'==document.location.protocol?\'https://\':\'http://\')+g,tag.parentNode.insertBefore(n,tag)}(window,document,"script","assets.giocdn.com/2.1/gio.js","gio");\n'

  content += `gio('init', '${context.growingIOProjectId}', {});\n`
  content += `gio('setUserId', '${context.userId}');\n`

  const values = {
    userSource: context.userSource,
    tenantId: context.tenantId,
    tenant: context.tenantName,
    org: context.orgShortName,
    orgId: context.orgId,
    orgType: context.orgExtType,
    orgFullName: context.orgFullName,
    licenseMode: context.licenseMode === 'project' ? '项目级' : '公司级',
    applicationMode: context.applicationMode,
    pathname: window.location.pathname,
    module: context.moduleName
  }

  Object.keys(values).forEach(key => {
    if (values[key]) {
      content += `gio('page.set', '${key}', '${values[key]}');\n`
    }
  })

  content += 'gio(\'send\');\n'

  const script = document.createElement('script')
  script.type = 'text/javascript'
  try {
    script.appendChild(document.createTextNode(content))
  } catch (ex) {
    script.text = content
  }

  document.head.appendChild(script)
}
