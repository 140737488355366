import group from './group.svg'
import company from './company.svg'
import command from './command.svg'
import project from './project.svg'
import production from './production.svg'
import specialized from './specialized.svg'

export default {
  'org-company': company,
  'org-project': project,
  'org-company-root': group,
  'org-project-command': command,
  'org-project-production': production,
  'org-company-specialized': specialized
}
