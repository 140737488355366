import { error, notify } from 'mussel'

import relogin from './relogin'

export function showSuccessMessage (message) {
  notify('success', message)
}

export function showFatalErrorMessage (message) {
  notify('error', message)
}

export function showBizErrorMessage (message, callback) {
  error(message, callback)
}

export function promptReLogin () {
  error('用户身份凭证已过期，请重新登录。', relogin)
}
