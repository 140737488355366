import { query } from '../utils'

function parseX36 (v) {
  const i = (v != null) && parseInt(v, 36)

  return isNaN(i) ? undefined : i
}

async function loadModuleContext () {
  const {
    applicationId: appIdX36,
    orgId: orgIdX36
  } = query

  const applicationId = parseX36(appIdX36)
  const orgId = parseX36(orgIdX36)
  const moduleId = this.moduleId || query.moduleId

  try {
    const params = {
      applicationId,
      moduleId,
      orgId,
      moduleUrl: window.location.pathname + window.location.search
    }
    if (window.performance?.navigation?.type === 1) params.log = 'n'

    const data = await this.$http.get({
      url: '/services/module-context',
      params,
      throwAllException: true,
      promptBizException: false
    })

    this.setContext({ moduleId, ...data })
    this.initialized = true
    this.$emit('load', this.context)

    if (this.$router && this.$router.currentRoute.path !== '/') {
      this.$router.replace('/')
    }
  } catch ({ code, desc, details }) {
    if (code === 'module_access_denied' && !this.hideFrame) {
      this.redirectModuleUrl({
        applicationId,
        orgId,
        moduleId: details.possibleModuleId,
        moduleUrl: details.possibleModuleUrl
      })
    } else if (code === 'module_access_denied' && this.hideFrame) {
      this.showPromptView('not permitted', '您没有权限查看当前页面')
    } else {
      this.showPromptView(code, desc)
    }
  }
}

async function loadPortalContext () {
  try {
    const params = {}

    if (window.performance?.navigation?.type === 1) params.log = 'n'
    if (this.moduleName) params.moduleName = this.moduleName

    const data = await this.$http.get({
      url: '/services/portal-context',
      params,
      throwBizException: true,
      promptBizException: false
    })

    this.setContext(data)
    this.initialized = true
    this.$emit('load', this.context)
  } catch ({ code, desc, detail }) {
    this.showPromptView(code, desc)
  }
}

let cachedApplications

async function loadApplications (force) {
  if (force || !Array.isArray(cachedApplications)) {
    cachedApplications = null
    const data = await this.$http.get({
      url: force ? '/services/applications?force=true' : '/services/applications'
    })

    if (!data) return

    cachedApplications = data
  }

  return cachedApplications.map(item => ({ ...item }))
}

let cachedMessages

async function loadMessages () {
  if (!Array.isArray(cachedMessages)) {
    const data = await this.$http.get({
      url: '/services/product-maintenances'
    })

    if (!data) return

    cachedMessages = data
  }

  return cachedMessages.map(item => ({ ...item }))
}

function loadTodoList () {
  return this.$http.get({
    url: '/workflow-task/services/runtime/tasks',
    params: {
      active: true,
      offset: 0,
      limit: 20,
      scope: 'all',
      total: false
    },
    promptBizException: false,
    promptFatalException: false
  })
}

export {
  loadPortalContext,
  loadModuleContext,
  loadApplications,
  loadMessages,
  loadTodoList
}
