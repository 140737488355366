const search = window.location.search?.substr(1)
const query = {}

search?.split('&').forEach(el => {
  const [key, value] = el.split('=')
  query[key] = decodeURI(value)
})

function resetModuleId (params, options, menus) {
  const module = menus.find(v => v.url === options.moduleUrl)
  if (module?.moduleId && module?.moduleId !== '-1') {
    params.push(`moduleId=${module.moduleId}`)
  }
}

function openUrl (url, params, newTab) {
  url += (
    params.length
      ? (url.indexOf('?') > 0 ? '&' : '?') + params.join('&')
      : ''
  )

  if (newTab || url.includes('http')) window.open(url)
  else window.location.href = url
}

function redirectModuleUrl (options) {
  const {
    newTab,
    orgId = this.context?.orgId,
    moduleId = this.context?.moduleId,
    moduleUrl = this.context?.moduleUrl || window.location.pathname,
    applicationId = this.context?.applicationId,
    ignoreModuleId = false,
    query: queries = {}
  } = options

  const params = []

  if (applicationId) params.push(`applicationId=${Number(applicationId).toString(36)}`)
  if (orgId) params.push(`orgId=${orgId.toString(36)}`)

  if (moduleId && !ignoreModuleId) {
    params.push(`moduleId=${moduleId}`)
  } else if (ignoreModuleId && options.moduleUrl) {
    resetModuleId(params, options, this.context.menus)
  }

  Object.keys(queries).forEach(key => {
    if (['applicationId', 'orgId', 'moduleId'].indexOf(key) === -1) {
      params.push(`${key}=${queries[key]}`)
    }
  })

  openUrl(moduleUrl, params, newTab)
}

function redirectUrl (options) {
  const {
    url,
    newTab = true,
    orgId = this.context?.orgId,
    applicationId = this.context?.applicationId,
    query: queries = {}
  } = options

  const params = []

  if (applicationId) params.push(`applicationId=${Number(applicationId).toString(36)}`)
  if (orgId) params.push(`orgId=${orgId.toString(36)}`)

  Object.keys(queries).forEach(key => {
    if (['applicationId', 'orgId'].indexOf(key) === -1) {
      params.push(`${key}=${queries[key]}`)
    }
  })

  openUrl(url, params, newTab)
}

export {
  query,
  redirectUrl,
  redirectModuleUrl
}
