let el
let counter = 0

export function start (timeout = 10000) {
  counter++

  if (!el) {
    el = document.createElement('div')
    el.className = 'mc-page-loading-bar'
    document.body.appendChild(el)
  }

  if (counter === 1) el.setAttribute('status', 'init')

  setTimeout(() => {
    el.setAttribute('status', 'loading')
  }, 1)
}

export function complete () {
  if (counter > 0) counter--
  if (counter < 1) el?.setAttribute('status', 'complete')

  setTimeout(
    () => (counter < 1) && el?.removeAttribute('status'),
    200
  )
}
