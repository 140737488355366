//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrgSelector from './org-selector/index.vue'
import UserMenu from './user-menu.vue'
import AppsMenu from './apps-menu.vue'
import LettersMenu from './letters.vue'

export default {
  name: 'McPageHeader',
  components: {
    OrgSelector,
    UserMenu,
    AppsMenu,
    LettersMenu
  },
  inject: ['context'],
  computed: {
    isPortal () {
      return this.$application.frameMode === 'portal'
    },
    title () {
      return this.$application.title || (
        this.isPortal
          ? this.context.portalName
          : this.context.applicationTitle
      )
    },
    icon () {
      return this.$application.logoIcon || undefined
    },
    logo () {
      return this.icon
        ? undefined
        : (
          this.$application.logo ||
          this.context.applicationLogo ||
          this.context.tenantLogo
        )
    }
  },
  mounted () {

  }
}
