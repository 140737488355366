//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inject: ['application', 'breadcrumbs'],
  props: {
    alwaysVisible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible () {
      return this.alwaysVisible || this.items.length
    },
    items () {
      const items = []
      for (let i = 1, len = this.breadcrumbs.length; i < len; i++) {
        if (len > 8 && i > 2 && i < len - 5) {
          if (i === 3) items.push({})
        } else {
          items.push(this.breadcrumbs[i])
        }
      }
      return items
    }
  },
  methods: {
    go (index) {
      if (index === this.breadcrumbs.length - 1) {
        return
      }
      this.application.routerGoIndex(index)
    }
  }
}
