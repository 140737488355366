//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TreeNodes from './tree-nodes.vue'
import SearchNodes from './search-nodes.vue'

// import { getOrgIcon } from './org-utils'

export default {
  name: 'McPageOrgSelector',
  components: {
    TreeNodes,
    SearchNodes
  },
  inject: ['context'],
  provide () {
    return {
      selector: this
    }
  },
  props: ['orgId'],
  data () {
    return {
      hover: false,
      readonly: true,
      expanded: false,
      searchText: ''
    }
  },
  computed: {
    mode () {
      return this.readonly ? 'dropdown' : 'x'
    },
    icon () {
      return (!this.readonly || this.hover)
        ? 'search'
        : 'tree' // getOrgIcon(orgType, orgExtType)
    },
    trigger () {
      return this.readonly ? 'dropdown' : 'x'
    },
    inputValue () {
      return this.readonly
        ? (this.context.orgShortName || this.context.orgName)
        : this.searchText
    }
  },
  watch: {
    expanded (v) {
      if (!v) this.readonly = true
    }
  },
  methods: {
    onLeftButtonClick () {
      if (this.readonly) this.readonly = false
      setTimeout(() => { this.expanded = true }, 100)
      const input = this.$el.querySelector('input')
      input.focus()
      input.select()
    },
    onRightButtonClick () {
      if (this.readonly) this.toggleExpand()
      else this.clear()
      this.$el.querySelector('input').focus()
    },
    onMouseOver () {
      this.hover = true
    },
    onMouseLeave () {
      this.hover = false
    },
    onInput (value) {
      this.searchText = value
    },
    onInputClick () {
      if (this.readonly) this.toggleExpand()
    },
    onEscKey () {
      this.readonly = true
    },
    clear () {
      this.searchText = ''
      this.$el.querySelector('input').focus()
    },
    toggleExpand () {
      this.expanded = !this.expanded
    },
    onNodeClick (node) {
      if (node.id.toString() === this.context.orgId.toString()) return
      this.$application.redirectModuleUrl({
        orgId: node.id
      })
    }
  }
}
