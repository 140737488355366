//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Dropdown } from 'mussel'

export default {
  name: 'McPageAppsMenu',
  extends: Dropdown,
  inject: ['context'],
  data () {
    return {
      items: [],
      groups: [],
      isDingTalk: window.navigator.userAgent?.indexOf('DingTalk') !== -1
    }
  },
  methods: {
    async loadData (force) {
      if (force !== true && this.items.length) return
      const data = await this.$application.loadApplications(force === true)
      if (!data) return
      this.items = data
      this.filter()
    },
    filter (key = '') {
      const defaultGroup = {
        name: '_',
        items: []
      }
      const indexes = {
        _: defaultGroup
      }
      this.groups = [defaultGroup]
      this.items
        .filter(item => {
          return !key || item.name.indexOf(key) >= 0
        })
        .forEach(item => {
          const { appGroup = '_' } = item
          let group = indexes[appGroup]
          if (!group) {
            group = {
              name: appGroup,
              items: []
            }
            indexes[appGroup] = group
            this.groups.push(group)
          }
          group.items.push(item)
        })
    },
    search (key = '') {
      if (!this.items.length) return
      if (this.$searchTimer) clearTimeout(this.$searchTimer)

      this.$searchTimer = setTimeout(() => {
        this.filter(key.trim())
      }, 500)
    },
    onItemClick (item) {
      const orgId = this.context.orgId
      const url = `/applications/${item.id}${orgId ? `?orgId=${orgId}` : ''}`

      if (this.isDingTalk) window.location.href = url
      else window.open(url)
    }
  }
}
