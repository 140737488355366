//
//
//
//

import { getOrgIcon } from './org-utils'

export default {
  props: {
    nodeType: String,
    extType: String,
    root: Boolean
  },
  computed: {
    icon () {
      return getOrgIcon(this.nodeType, this.extType, this.root)
    }
  }
}
