import application from './application.svg'
import bi from './bi.svg'
import contract from './contract.svg'
import dashboard from './dashboard.svg'
import doc from './doc.svg'
import economy from './economy.svg'
import finance from './finance.svg'
import laborSubcontract from './labor-subcontract.svg'
import labor from './labor.svg'
import machine from './machine.svg'
import materials from './materials.svg'
import oa from './oa.svg'
import party from './party.svg'
import pm from './pm.svg'
import progress from './progress.svg'
import quality from './quality.svg'
import quantity from './quantity.svg'
import report from './report.svg'
import safety from './safety.svg'
import setting from './setting.svg'
import subcontract from './subcontract.svg'
import task from './task.svg'

export default {
  application,
  bi,
  contract,
  dashboard,
  doc,
  economy,
  finance,
  laborSubcontract,
  labor,
  machine,
  materials,
  oa,
  party,
  pm,
  progress,
  quality,
  quantity,
  report,
  safety,
  setting,
  subcontract,
  task
}
