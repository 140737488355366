//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrgIcon from './icon.vue'

export default {
  components: {
    OrgIcon
  },
  inject: ['selector'],
  props: {
    level: {
      type: Number,
      default: 0
    },
    parentId: Number,
    searchText: String
  },
  data () {
    return {
      nodes: []
    }
  },
  watch: {
    searchText () {
      this.queryOrgNodes()
    }
  },
  methods: {
    getFullName (node) {
      const fullNameArray = node.fullName.split('|')
      return fullNameArray.length > 1 ? fullNameArray.slice(1).join('|') : fullNameArray.join(' / ')
    },
    getName (node) {
      return node.shortName === node.name
        ? node.shortName
        : (node.shortName && node.name
          ? `${node.shortName}(${node.name})`
          : node.shorName || node.name)
    },
    async queryOrgNodes () {
      if (this.$searchTimer) clearTimeout(this.$searchTimer)

      this.$searchTimer = setTimeout(async () => {
        const data = this.searchText.trim()
          ? await this.$http.get({
            url: '/services/org-nodes',
            params: {
              preset: 'search',
              key: this.searchText,
              limit: 20
            }
          })
          : []
        if (data) this.nodes = data
      }, 500)
    },
    onNodeClick (node) {
      this.selector.onNodeClick(node)
    }
  }
}
