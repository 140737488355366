//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LoadingAnimation',
  props: {
    size: {
      default: '40px'
    }
  },
  computed: {
    innerStyles () {
      const size = parseInt(this.size)
      return {
        transform: 'scale(' + (size / 164) + ')'
      }
    },
    styles () {
      return {
        width: this.size,
        height: this.size
      }
    }
  }
}
