export function getOrgIcon (nodeType, extType, root) {
  return nodeType === 'company'
    ? (
        root
          ? 'org-company-root'
          : (
              'org-company'
            )
      )
    : (
        extType === 'command'
          ? 'org-project-command'
          : (
              extType === 'project'
                ? 'org-project'
                : 'org-project-production'
            )
      )
}

const root = { eof: true }
const indexes = {}

export function appendChildNodes (parentId, childNodes, usePermittedOrgs) {
  const parent = parentId >= 0 ? indexes[parentId] : root

  if (!parent.childNodes) {
    parent.childNodes = []
    parent.eof = true
  }

  const nodes = []

  if (!usePermittedOrgs && childNodes.length > 20) {
    childNodes.pop()
    parent.eof = false
  }
  childNodes.forEach(node => {
    if (!indexes[node.id]) {
      indexes[node.id] = node
    }
    parent.childNodes.push(node)
    nodes.push({ ...node })
  })
  return {
    nodes,
    eof: parent.eof
  }
}

export function getChildNodes (parentId) {
  const parent = parentId >= 0 ? indexes[parentId] : root
  return {
    nodes: parent.childNodes?.map(node => ({ ...node })),
    eof: parent.eof
  }
}
