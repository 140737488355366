<template>
  <div>
    <mc-org-tree-node
      v-for="(node, idx) in nodes"
      :key="(parentId || -1) + '_' + node.id + '_' + idx"
      :idx="idx"
      :node="node"
      :level="level" />
    <div
      v-if="!eof"
      class="mu-list-item"
      :style="{ paddingLeft: indent }"
      @click="loadNodes(true)">
      <mu-icon icon="ellipsis" />
      加载更多
    </div>
  </div>
</template>

<script>
  import { getChildNodes, appendChildNodes } from './org-utils'

  const unitIndent = 16

  export default {
    name: 'McOrgTreeNodes',
    inject: ['context'],
    props: {
      level: {
        type: Number,
        default: 0
      },
      parentId: {
        type: Number,
        default: -1
      }
    },
    data () {
      return {
        nodes: [],
        eof: true
      }
    },
    computed: {
      indent () {
        return unitIndent * (this.level + 1) + 'px'
      }
    },
    mounted () {
      this.loadNodes()
    },
    methods: {
      appendNodes (nodes) {
        this.nodes.push(...nodes)
      },
      async loadPermittedOrgs () {
        const data = await this.$http.get({
          blocking: true,
          url: '/services/org-nodes',
          params: {
            offset: 0,
            limit: 1000,
            preset: 'search',
            applicationId: this.context.applicationId
          }
        })

        let result

        if (data?.length) {
          const hash = {}

          data.forEach(org => {
            org.isValid = true
            hash[org.id] = org
          })
          data.forEach(org => {
            const arr = org.fullId.split('|')

            for (const orgId of arr) {
              if (orgId !== org.id.toString() && hash[orgId]) {
                delete hash[org.id]
                break
              }
            }
          })

          result = Object
            .keys(hash)
            .map(key => hash[key])
            .sort((a, b) => {
              return a.id - b.id
            })
        }

        return result
      },
      async loadChildOrgs (parentId, offset) {
        const data = await this.$http.get({
          url: '/services/org-nodes',
          params: {
            limit: 21,
            offset,
            preset: 'tree',
            parent: parentId < 0 ? undefined : parentId
          }
        })
        return data
      },
      async loadNodes (nextPage) {
        const { nodes, eof } = getChildNodes(this.parentId)

        if (nodes?.length && !nextPage) {
          this.appendNodes(nodes)
          this.eof = eof
        } else {
          const { licenseMode, userId, tenantId } = this.context
          const usePermittedOrgs = (
            this.parentId === -1 &&
            licenseMode === 'project' &&
            userId !== tenantId
          )

          const data = usePermittedOrgs
            ? await this.loadPermittedOrgs()
            : await this.loadChildOrgs(this.parentId, this.nodes.length)

          if (!data) return
          const ret = appendChildNodes(this.parentId, data, usePermittedOrgs)
          this.appendNodes(ret.nodes)
          this.eof = ret.eof
        }
      }
    }
  }
</script>
