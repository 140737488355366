//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Dropdown } from 'mussel'
import dayjs from 'dayjs'

export default {
  name: 'McPageLettersMenu',
  extends: Dropdown,
  inject: ['context'],
  data () {
    return {
      messages: [],
      todoList: [],
      isWorkflowRegistered: false,
      lastClickTime: null
    }
  },
  computed: {
    lettersLength () {
      return this.messages.length + this.todoList.length
    }
  },
  watch: {
    context: {
      handler: function (value) {
        // this.loadTodoList()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    // this.isWorkflowRegistered = !!this.context.workflowEngine
    this.loadMessages()
    // if (this.isWorkflowRegistered) {
    this.loadTodoList()
    // }
  },
  methods: {
    async refreshData () {
      const currentTime = dayjs()
      if (
        !this.lastClickTime ||
        currentTime.diff(this.lastClickTime) > 30 * 1000
      ) {
        this.lastClickTime = currentTime
        await this.loadTodoList()
      }
      this.onClick(...arguments)
    },
    async loadMessages () {
      if (this.messages.length) return
      const data = await this.$application.loadMessages()
      if (data) this.messages = data
    },
    loadTodoList () {
      this.$application.loadTodoList()
        .then(data => {
          if (data) this.todoList = data.tasks
        })
    },
    redirect (task) {
      this.$application.redirectUrl({
        url: '/workflow-task/task-center.html',
        newTab: true,
        query: {
          taskId: task.taskId,
          processInstanceId: task.processInstanceId
        }
      })
    },
    toTaskCenter () {
      this.$application.redirectModuleUrl({
        moduleUrl: '/workflow-task/task-center.html',
        newTab: true
      })
    },
    getTaskStartDate (date) {
      if (date) {
        return dayjs(date).format('YY-MM-DD')
      }
      return ''
    },
    getTaskStartTime (date) {
      if (date) {
        return dayjs(date).format('HH:mm:ss')
      }
      return ''
    },
    getTaskBookmarkColor (item) {
      if (item.sourceAction === 'reject') {
        return 'mu-text-color-danger'
      }
      if (item.taskStatus === 'waiting') {
        return 'mu-text-color-warning'
      }
      return 'mu-text-color-primary'
    },
    getTaskStatusName (item) {
      if (item.sourceAction === 'reject') {
        return '退'
      }
      if (item.taskStatus === 'waiting') {
        return '抢'
      }
      return '办'
    },
    getBorderColor (item) {
      if (item.sourceAction === 'reject') {
        return '#f57a79'
      }
      if (item.taskStatus === 'waiting') {
        return '#faad14'
      }
      return '#008cd6'
    }
  }
}
