import { registerIcons } from 'mussel'

import user from '@icons/user.svg'
import home from '@icons/home.svg'
import password from '@icons/key.svg'
import logout from '@icons/logout.svg'
import message from '@icons/message.svg'
import applications from '@icons/apps.svg'
import module from '@icons/box.svg'
import command from '@icons/command.svg'
import childMenu from '@icons/menu.svg'
import file from '@icons/file.svg'
import download from '@icons/download.svg'
import bell from '@icons/bell.svg'

import apps from './application'
import orgs from './org-type'

import bookmark from '@icons/bookmark.svg'
import run from '@icons/run.svg'

registerIcons({
  ...apps,
  ...orgs,
  home,
  user,
  logout,
  message,
  password,
  applications,
  module,
  command,
  childMenu,
  file,
  download,
  bell,
  bookmark,
  run
})
