//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MenuItem } from 'mussel'

export default {
  extends: MenuItem,
  inject: ['context'],
  props: ['item'],
  computed: {
    isActive () {
      return this.item.active || this.item.moduleId === this.context.moduleId
    }
  },
  mounted () {
    if (this.isActive) {
      const group = this.menuGroup
      if (group?.isExpander) group.expand()
      else if (group?.menuGroup?.isExpander) group.menuGroup.expand()
    }
  },
  methods: {
    onClick (event) {
      if (!this.disabled && !this.isActive && this.item.url) {
        this.$application.redirectModuleUrl({
          newTab: event.ctrlKey || event.metaKey,
          moduleId: this.item.moduleId,
          moduleUrl: this.item.url
        })
      }
    }
  }
}
