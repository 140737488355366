import Vue from 'vue'
import * as mussel from 'mussel'

import './pcss'

import Page from './page/index.vue'
import OrgTreeNode from './page/header/org-selector/tree-node.vue'
import OrgTreeNodes from './page/header/org-selector/tree-nodes.vue'

import './icons'

import {
  http,
  query,
  blockingAnimation,
  showSuccessMessage,
  showBizErrorMessage,
  showFatalErrorMessage
} from './utils'

if (Vue) {
  Vue.prototype.$http = http

  Vue.component('McPage', Page)
  Vue.component('McOrgTreeNode', OrgTreeNode)
  Vue.component('McOrgTreeNodes', OrgTreeNodes)
}

const theme = mussel?.variables

export {
  Page,
  query,
  theme,
  blockingAnimation,
  showSuccessMessage,
  showBizErrorMessage,
  showFatalErrorMessage
}
