//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MenuItem from './item.vue'

export default {
  name: 'McPageMainMenu',
  components: {
    MenuItem
  },
  props: {
    menus: Array,
    width: String
  },
  data () {
    return {
      collapsed: document.documentElement.clientWidth < 1500,
      rootMenus: []
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    async loadData () {
      const data = this.menus || (
        await this.$http.get({
          url: '/services/menus'
        })
      )
      if (!data) return

      const indexes = {}
      const menus = []

      data
        .sort((a, b) => a.sortNum - b.sortNum)
        .forEach(item => {
          const { id, parentId } = item

          const existItem = indexes[id]
          if (existItem) item = Object.assign(existItem, item)
          else indexes[id] = item

          if (parentId.toString() === '-1') {
            menus.push(item)
          } else {
            const parent = indexes[parentId] || {}

            if (isNaN(parent.id)) {
              indexes[parentId] = Object.assign(
                parent,
                { id: parentId, menus: [] }
              )
            } else if (!parent.menus) {
              parent.menus = []
            }

            parent.menus.push(item)
          }
        })
      this.rootMenus = menus
      this.$application.setMenus(data)
    }
  }
}
