//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'

import { setTheme } from 'mussel'

import PageHeader from './header/index.vue'
import MainMenu from './menu/index.vue'
import Breadcrumb from './breadcrumb.vue'

import {
  loadPortalContext,
  loadModuleContext,
  loadApplications,
  loadMessages,
  loadTodoList
} from './actions'

import {
  http,
  query,
  redirectUrl,
  redirectModuleUrl,
  loadingAnimation,
  blockingAnimation,
  showSuccessMessage,
  showBizErrorMessage,
  showFatalErrorMessage,
  injectGrowingIOScript
} from '../utils'

export default {
  name: 'McPage',
  components: {
    PageHeader,
    MainMenu,
    Breadcrumb
  },
  provide () {
    return {
      application: this,
      context: this.context,
      breadcrumbs: this.breadcrumbs
    }
  },
  props: {
    theme: null,
    frameMode: {
      type: String,
      default: 'module',
      validator (value) {
        return ['module', 'portal', 'standalone'].indexOf(value) !== -1
      }
    },
    crumbHomeTitle: String,
    crumbHomeIcon: {
      type: String,
      default: 'home'
    },
    crumbsVisible: {
      type: String,
      default: 'auto',
      validator (value) {
        return ['auto', 'always'].indexOf(value) !== -1
      }
    },
    moduleId: String,
    moduleName: String,
    title: String,
    logo: String,
    logoIcon: String,
    logoutText: String,
    logoutClose: Boolean,
    logoutService: String,
    menus: Array
  },
  data () {
    return {
      initialized: false,
      context: {
        batchId: '',
        userId: null,
        userName: '',
        tenantId: null,
        tenantCode: null,
        tenantLogo: '',
        tenantName: '',
        orgId: null,
        orgName: '',
        orgShortName: '',
        orgType: '',
        orgExtType: '',
        moduleId: '',
        moduleName: '',
        portalName: '',
        permissions: [],
        applicationId: '',
        applicationLogo: '',
        applicationTitle: '',
        licenseMode: null,
        extraMenus: [],
        menus: this.menus || []
      },
      breadcrumbs: [
        {
          name: '/',
          title: this.crumbHomeTitle
        }
      ],
      hideFrame: false
    }
  },
  computed: {
    menuVisible () {
      return !this.hideFrame && (this.frameMode === 'module' || this.menus)
    }
  },
  beforeCreate () {
    this.loadingAnimation = loadingAnimation
    this.blockingAnimation = blockingAnimation

    Vue.prototype.$application = this
  },
  created () {
    Vue.prototype.$context = this.context

    if (this.theme) {
      setTheme(this.theme)
    }

    if (this.$store) {
      this.$store.$http = this.$http
      this.$store.$context = this.context
    }

    this.hideFrame = query.frame === 'n'
  },
  mounted () {
    switch (this.frameMode) {
      case 'portal':
        this.loadPortalContext()
        break
      case 'module':
        this.loadModuleContext()
        break
    }
  },
  methods: {
    setMenus (menus = []) {
      this.context.menus = menus

      const menu = menus.find(
        v => v.moduleId === this.context.moduleId
      )

      if (menu) document.title = menu.aliasName
    },
    setContext (data) {
      const {
        applicationId,
        moduleId,
        batchId,
        orgId,
        licenseMode,
        growingIOProjectId
      } = data

      if (this.moduleName) {
        data.moduleName = this.moduleName
      }

      if (batchId) {
        window.webframeContext = {
          batchId,
          licenseMode,
          growingIOProjectId
        }
      }

      if (growingIOProjectId) {
        injectGrowingIOScript(data)
      }

      http.setContextHeaders({
        applicationId,
        moduleId,
        batchId,
        orgId
      })

      Object.assign(this.context, data, {
        isAdmin: data.userId === data.tenantId
      })
    },
    showPromptView (code, desc) {
      this.showBizErrorMessage(desc || '页面初始化数据加载失败！')
    },
    routerPush ({ name, title, params, skipRoute }) {
      const len = this.breadcrumbs.length

      this.breadcrumbs.push({
        name,
        params,
        index: len,
        title: title || name
      })

      if (!skipRoute) {
        this.$router?.replace({ name, params })
      }
    },
    routerReplace ({ name, title, params, skipRoute }) {
      const len = this.breadcrumbs.length

      this.breadcrumbs[len ? len - 1 : 0] = {
        name,
        params,
        index: len - 1,
        title: title || name
      }

      if (!skipRoute) {
        this.$router?.replace({ name, params })
      }
    },
    updateBreadcrumbsTitle (title, index) {
      const updateIndex = index ?? this.breadcrumbs.length - 1

      this.breadcrumbs[updateIndex].title = title
    },
    routerGoIndex (index, skipRoute) {
      const { name, params } = this.breadcrumbs[index]
      const len = this.breadcrumbs.length

      this.breadcrumbs.splice(index + 1, len - index - 1)

      if (!skipRoute) {
        this.$router?.replace({ name, params })
      }
    },
    validatePermissions () {
      const permissions = [...arguments]

      return this.context.permissions.some(
        v => permissions.some(t => t === v)
      )
    },

    loadMessages,
    loadTodoList,
    loadApplications,
    loadPortalContext,
    loadModuleContext,
    redirectUrl,
    redirectModuleUrl,
    showSuccessMessage,
    showBizErrorMessage,
    showFatalErrorMessage
  }
}
