import Vue from 'vue'

import Loading from '../page/loading.vue'

let el
let counter = 0

export function show (timeout = 10000) {
  counter++
  if (!el) {
    el = document.createElement('div')
    const container = document.createElement('div')
    el.appendChild(container)
    el.className = 'mu-modal-mask mc-blocking-animation'
    el.style.zIndex = 998;
    (() => new Vue({
      el: container,
      render: h => h(Loading)
    }))()
    document.body.appendChild(el)
  }
  if (counter === 1) el?.setAttribute('visible', '')
}

export function hide () {
  if (counter > 0) counter--
  if (counter < 1) el?.removeAttribute('visible')
}
