//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrgIcon from './icon.vue'

const unitIndent = 16

export default {
  name: 'McOrgTreeNode',
  components: {
    OrgIcon
  },
  inject: ['selector'],
  props: {
    node: Object,
    level: {
      type: Number,
      default: 0
    },
    idx: Number
  },
  data () {
    return {
      expanded: !this.level && !this.node.isLeaf && !this.idx
    }
  },
  computed: {
    indent () {
      return unitIndent * (this.level + 1) + 'px'
    },
    expandIcon () {
      return this.node.isLeaf
        ? undefined
        : this.expanded ? 'key-down' : 'key-right'
    }
  },
  methods: {
    toggleExpand () {
      if (!this.node.isLeaf) this.expanded = !this.expanded
    },
    onNodeClick () {
      if (this.node.isValid) this.selector.onNodeClick(this.node)
    }
  }
}
