//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Dropdown } from 'mussel'

export default {
  name: 'McPageUserMenu',
  extends: Dropdown,
  inject: ['context'],
  data () {
    return {
      qrcodeLoaded: false,
      qrcodeDialogVisible: false,
      appcodeLoaded: false,
      appcodeDialogVisible: false
    }
  },
  computed: {
    logoutText () {
      return this.$application.logoutText || '退出登录'
    }
  },
  methods: {
    showApp () {
      this.appcodeDialogVisible = true
      this.appcodeLoaded = true
    },
    showOTP () {
      this.qrcodeDialogVisible = true
      this.qrcodeLoaded = true
    },
    redirect (task) {
      this.$http.post('/services/get-module-by-url', { orgId: task.orgId, url: task.moduleUrl }).then(data => {
        window.open(`${task.moduleUrl}${task.moduleUrl.indexOf('?') > -1 ? '&' : '?'}applicationId=${Number(data.applicationId).toString(36)}&orgId=${Number(data.orgId).toString(36)}&frame=n&bizId=${task.bizId}`)
      })
    },
    changePassword () {
      window.open('/password.html')
    },
    logout () {
      if (this.$application.logoutClose) {
        window.close()
      } else {
        window.location.href = `/logout${this.$application.logoutService ? `?service=${this.$application.logoutService}` : ''}`
      }
    },
    changeTenant () {
      window.location.href = '/tenants.html'
    },
    onExtraMenuClick (item) {
      this.$application.redirectUrl({
        url: item.url
      })
    }
  }
}
