<template>
  <div
    class="mu-flex-box"
    style="height: 100%; width: 100%;"
    align-items="center"
    justify-content="center">
    <div class="spinner-container">
      <div :style="styles" class="spinner spinner--hexagon">
        <div :style="innerStyles" class="spinner-inner">
          <ul class="hexagon-container">
            <li class="hexagon hex_1" />
            <li class="hexagon hex_2" />
            <li class="hexagon hex_3" />
            <li class="hexagon hex_4" />
            <li class="hexagon hex_5" />
            <li class="hexagon hex_6" />
            <li class="hexagon hex_7" />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LoadingAnimation',
    props: {
      size: {
        default: '40px'
      }
    },
    computed: {
      innerStyles () {
        const size = parseInt(this.size)
        return {
          transform: 'scale(' + (size / 164) + ')'
        }
      },
      styles () {
        return {
          width: this.size,
          height: this.size
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .spinner-container {
    padding: 10px;
    background: rgba(245, 245, 245, 0.7);
    border-radius: 4px;
    box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.12),
      0 1.6px 3.6px rgba(0, 0, 0, 0.12);
  }
  .spinner {
    overflow: hidden;
  }
  .spinner-inner {
    position: relative;
    width: 160px;
    height: 160px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid rgba(0, 140, 214, 0.5);
    transform-origin: top left;
    display: block;
    &:before {
      content: "";
      width: 164px;
      height: 164px;
      display: block;
      position: absolute;
      border: 7px solid rgba(0, 140, 214, 0.5);
      border-radius: 50%;
      top: -2px;
      left: -2px;
      box-sizing: border-box;
      // border-bottom-color: transparent;
      // border-left-color: transparent;
      // border-right-color: transparent;
      clip: rect(0px, 35px, 35px, 0px);
      z-index: 10;
      animation: hexagon-rotate infinite;
      animation-duration: 3s;
      animation-timing-function: linear;
    }
    &:after {
      content: "";
      width: 164px;
      height: 164px;
      display: block;
      position: absolute;
      border: 2px solid rgba(0, 140, 214, 0.5);
      border-radius: 50%;
      top: -2px;
      left: -2px;
      box-sizing: border-box;
      // transform: hexagon-rotate(30deg);
      // border-bottom-color: transparent;
      // border-left-color: transparent;
      // border-right-color: transparent;
      clip: rect(0px, 164px, 150px, 0px);
      z-index: 9;
      // animation: hexagon-rotate2 infinite, hexagon-rotate3 infinite;
      // animation-duration: 3s;
      // animation-timing-function: linner;
      animation: hexagon-rotate2 3s linear infinite;
    }
  }
  .hexagon-container {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    top: 33px;
    left: 41px;
    border-radius: 50%;
  }
  .hexagon {
    position: absolute;
    width: 40px;
    height: 23px;
    background-color: rgba(0, 140, 214);
    &.hex_1 {
      background-color: rgba(0, 140, 214, 0.1);
      &:before {
        border-bottom: 11.5px solid rgba(0, 140, 214, 0.1);
      }
      &:after {
        border-top: 11.5px solid rgba(0, 140, 214, 0.1);
      }
    }
    &.hex_2 {
      background-color: rgba(0, 140, 214, 0.25);
      &:before {
        border-bottom: 11.5px solid rgba(0, 140, 214, 0.25);
      }
      &:after {
        border-top: 11.5px solid rgba(0, 140, 214, 0.25);
      }
    }
    &.hex_3 {
      background-color: rgba(0, 140, 214, 0.4);
      &:before {
        border-bottom: 11.5px solid rgba(0, 140, 214, 0.4);
      }
      &:after {
        border-top: 11.5px solid rgba(0, 140, 214, 0.4);
      }
    }
    &.hex_4 {
      background-color: rgba(0, 140, 214, 0.55);
      &:before {
        border-bottom: 11.5px solid rgba(0, 140, 214, 0.55);
      }
      &:after {
        border-top: 11.5px solid rgba(0, 140, 214, 0.55);
      }
    }
    &.hex_5 {
      background-color: rgba(0, 140, 214, 0.7);
      &:before {
        border-bottom: 11.5px solid rgba(0, 140, 214, 0.7);
      }
      &:after {
        border-top: 11.5px solid rgba(0, 140, 214, 0.7);
      }
    }
    &.hex_6 {
      background-color: rgba(0, 140, 214, 0.8);
      &:before {
        border-bottom: 11.5px solid rgba(0, 140, 214, 0.8);
      }
      &:after {
        border-top: 11.5px solid rgba(0, 140, 214, 0.8);
      }
    }
    &.hex_7 {
      background-color: rgba(0, 140, 214, 1);
      &:before {
        border-bottom: 11.5px solid rgba(0, 140, 214, 1);
      }
      &:after {
        border-top: 11.5px solid rgba(0, 140, 214, 1);
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: -11px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 11.5px solid rgba(0, 140, 214);
    }
    &:after {
      content: "";
      position: absolute;
      top: 23px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 11.5px solid rgba(0, 140, 214);
    }
  }
  @each $index, $top, $left in (1, 0px, 0px), (2, 0px, 42px), (3, 36px, 63px),
    (4, 72px, 42px), (5, 72px, 0px), (6, 36px, -21px), (7, 36px, 21px)
  {
    $time: 3s; // thx to @zeakd for this formula
    $delay: calc($time / 14);
    .hexagon.hex_#{$index} {
      top: $top;
      left: $left;
      //cubic-bezier(.155,1.105,.295,1.12)
      animation: hexagon-Animasearch $time ease-in-out infinite;
      animation-delay: $delay * $index;
    }
  }
  @keyframes hexagon-Animasearch {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    15%,
    50% {
      transform: scale(0.5);
      opacity: 0;
    }
    65% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes hexagon-rotate {
    0% {
      transform: rotate(0);
      clip: rect(0px, 35px, 35px, 0px);
    }
    50% {
      clip: rect(0px, 40px, 40px, 0px);
    }
    100% {
      transform: rotate(360deg);
      clip: rect(0px, 35px, 35px, 0px);
    }
  }
  @keyframes hexagon-rotate2 {
    0% {
      transform: rotate(0deg);
      clip: rect(0px, 164px, 150px, 0px);
    }
    50% {
      clip: rect(0px, 164px, 0px, 0px);
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(720deg);
      clip: rect(0px, 164px, 150px, 0px);
    }
  }
  @keyframes hexagon-rotate3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
